import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Title from '../components/Title';

import BackgroundImage from '../../assets/images/background-about-us.jpg';
import CHD from '../../assets/images/CHD.png';
import CHR from '../../assets/images/CHR.png';

const data = [
  {
    title: 'Why are we called Calf Hey?',
    description: `The company was started as a 'one man band' in a small rural village in West Yorkshire. To accommodate
    the first labelling machine, it was decided to move to a new house with a reasonably sized garage. A
    good number of houses in the area had names dating back many years. A property called 'Calf Hey' was for
    sale and it was planned to buy this property and start the business here, hence the name. ('Hey' is
    apparently an old Saxon word meaning 'Hedge' - the village also boasts a house called 'Lamb Hey'). In
    fact, at the last moment, the required planning permission was refused due to road width restrictions,
    but the business name stayed.`
  },
  {
    title: `Why Calf Hey 'Design' & 'Rotary'?`,
    description: `For the first few years, all our production was based on Hot Foil printing. This was ideal for cost
    effective labels of many types but, due to not being able to match all Pantone colours or include halftone
    screens, we knew we were missing out on some enquiries. We, therefore, invested in our first Rotary
    Letterpress machine to enable us to offer a wider range of products. We soon found that the two methods
    of production justified their own 'identity' with each having a dedicated team specialising in their
    particular range of products. However, they are sister companies and often work together on projects for
    customers.`
  },
  {
    title: `Why are we trade only printers?`,
    description: `Trying to establish a business from nothing, with virtually no work to start producing, it was going to
    be difficult to run a printing machine and look for printing work. Having many friends and colleagues in
    the printing industry, it was decided to concentrate on printing labels and supplying them at keen
    'trade only' prices. This allowed these contacts to do the selling and make a respectable 'mark-up' on
    the way. This policy has been continued throughout the history of Calf Hey, and ensures that any
    enquiries / orders channelled to us can never be 'switched' directly to us, which offers a protection
    for our customers.`
  },
  {
    title: `What studio services are available?`,
    description: [
      'Calf Hey has a full design studio that is available to all of our customers. We can send a digital or posted proof for a label that is being printed by ourselves. We also provide setting and design for artwork at a small fee. If you have any queries about supplying or producing artwork for print by Calf Hey, please see our ',
      <Link to="/artwork/">
        <strong>artwork page</strong>
      </Link>,
      ' or ',
      <Link to="/contact/">
        <strong>contact us.</strong>
      </Link>,
      ' We will be glad to advise you on how to get the best from your designs.'
    ]
  }
];

const Details = props => (
  <div className="col-md-6 col-xs-12">
    <div className="c-feature-15-container c-bg-white c-bg-opacity-small">
      <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border">{props.title}</h2>
      <p className="c-feature-15-desc">{props.description}</p>
    </div>
  </div>
);

const Contacts = () => (
  <div className="c-content-box c-size-md">
    <div className="container c-center">
      <div className="row">
        <div className="c-content-title-1">
          <h3 className="c-font-uppercase c-font-bold">Contact Details</h3>
          <div className="c-line-center c-theme-bg" />
        </div>

        <div className="col-md-6 c-margin-b-20">
          <div className="c-content-media-1" data-height="height">
            <img src={CHD} width="80%" className="c-center" alt="Calf Hey Design Ltd" />
            <h3 className="c-title c-font-uppercase c-font-bold">Calf Hey Design Ltd</h3>
            <p>
              Victoria House,
              <br />
              Victoria Street,
              <br />
              Lockwood,
              <br />
              Huddersfield,
              <br />
              West Yorkshire
              <br />
              HD1 3RG <br />
              <br />
              <strong>Tel:</strong> 01484 542795 <br />
              <strong>E-mail: </strong>{' '}
              <a href="mailto:sales@calf-hey.com" target="_blank" rel="noopener noreferrer">
                sales@calf-hey.com
              </a>
            </p>
            <Link to="/services/hotfoil/">
              <div
                className="c-content-label c-font-uppercase c-font-bold c-theme-bg c-margin-t-20"
                style={{ marginRight: '20px' }}
              >
                Hotfoil
              </div>
            </Link>
            <Link to="/services/thermal/">
              <div className="c-content-label c-font-uppercase c-font-bold c-theme-bg">Thermal</div>
            </Link>
          </div>
        </div>

        <div className="col-md-6">
          <div className="c-content-media-1" data-height="height">
            <img src={CHR} width="80%" className="c-center" alt="Calf Hey Rotary Ltd" />
            <h3 className="c-title c-font-uppercase c-font-bold">Calf Hey Rotary Ltd</h3>
            <p>
              Victoria House,
              <br />
              Victoria Street,
              <br />
              Lockwood,
              <br />
              Huddersfield,
              <br />
              West Yorkshire
              <br />
              HD1 3RG <br />
              <br />
              <strong>Tel:</strong> 01484 448033
              <br />
              <strong>E-mail:</strong>{' '}
              <a href="mailto:chris@calf-hey.co.uk" target="_blank" rel="noopener noreferrer">
                chris@calf-hey.co.uk
              </a>
            </p>
            <Link to="/services/letterpress/">
              <div
                className="c-content-label c-font-uppercase c-font-bold c-theme-bg  c-margin-t-20"
                style={{ marginRight: '20px' }}
              >
                Letterpress
              </div>
            </Link>
            <Link to="/services/digital/">
              <div className="c-content-label c-font-uppercase c-font-bold c-theme-bg">Digital</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const title = 'About Us';

const Company = () => (
  <Layout title={title}>
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title main={title} />
      </div>
    </div>

    <div className="c-content-feature-15 c-bg-img-center" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <div className="container">
        <div className="row display-flex">
          <Details title={data[0].title} description={data[0].description} />
          <Details title={data[1].title} description={data[1].description} />
          <Details title={data[2].title} description={data[2].description} />
          <Details title={data[3].title} description={data[3].description} />
        </div>
      </div>
    </div>

    <Contacts />
  </Layout>
);

export default Company;
